<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined>
          <v-card-title>
            <h3>
              <v-icon class="mr-3" color="primary">mdi-folder-pound</v-icon
              >Vamos iniciar uma nova solicitação
            </h3>
          </v-card-title>
          <v-card-text>
            <SolicitationTypeComponent />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SolicitationTypeComponent from "@/components/solicitation/SolicitationTypeComponent";
export default {
  components: {
    SolicitationTypeComponent
  }
};
</script>
